$m-ai-search-icon-margin-inline: 8px !default;
$m-ai-search-icon-size: ($m-ai-search-icon-margin-inline * 2 + 24px); // 24px is icon width in svg
$m-ai-search-box-shadow: 0px 4px 16px 0 rgba(20, 20, 20, 0.12);
$m-ai-search-survey-btn-size: 48px;
$m-ai-search-zindex: $zindex-modal !default;

.m-ai-search {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: var(--sticky-ai-search-height, 82px);
  padding-block: map-get($spacers, 4);
  margin-inline: -16px; // bootstrap container padding
  background-color: var(--color-body-bg);
  z-index: $m-ai-search-zindex;

  &.is-stuck {
    box-shadow: $m-ai-search-box-shadow;
  }

  &.is-open {
    position: fixed;
    inset: 0;
    height: 100vh;
    margin-inline: 0;
    padding-block-end: 0;

    main:has(&),
    #main-content:has(&) {
      z-index: $m-ai-search-zindex;
    }

    html:has(&),
    body:has(&) {
      overflow: hidden;

      /* Workaround for scrolling the body in Safari. Note that it always causes the scroll to reset to the top when the overlay is closed. */
      @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance:none) and (stroke-color:transparent) {
          position: fixed;
        }
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    display: none;
  }

  &__form {
    display: flex;
    gap: map-get($spacers, 3);
    margin-inline: map-get($spacers, 4);
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
  }

  &__input {
    padding-inline: $m-ai-search-icon-size;
  }

  &__icon {
    position: absolute;
    left: 0;
    margin-inline-start: $m-ai-search-icon-margin-inline;
    display: inline-flex;
    align-self: center;
    fill: var(--color-primary);
    pointer-events: none;
  }

  &__clear {
    position: absolute;
    right: 0;
    padding: 12px;
    display: inline-flex;
    align-self: center;
    font-size: 16px;
  }

  &__close {
    padding-block: map-get($spacers, 2);
    font-family: inherit;
    font-weight: $font-weight-bold;
  }

  &__overlay {
    flex-grow: 1;
    margin-block: map-get($spacers, 4);
    padding: map-get($spacers, 4);
    border-block-start: 1px solid var(--color-separator);
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    // TODO: think about add transition or not for this
    // transition-property: opacity, visibility;
    // transition-duration: var(--base-duration);
  
    .m-ai-search.is-open & {
      visibility: visible;
      opacity: 1;
    }
  }

  &__survey {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: map-get($spacers, 4);
    padding: map-get($spacers, 4);
    border: 2px solid var(--color-separator);
    border-radius: 8px;
  }

  &__survey-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $m-ai-search-survey-btn-size;
    height: $m-ai-search-survey-btn-size;
    border-radius: 50%;
    fill: var(--text-muted);

    &:hover {
      background-color: var(--color-frame-bg);
    }

    &:active,
    &:focus {
      background-color: color-mix(in hsl, var(--color-primary) 15%, transparent);
      fill: var(--color-primary);
      outline-width: 0;
    }
  }

  &__survey-thx {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: $m-ai-search-survey-btn-size;
    margin-inline: auto;
  }
}

// Force to make sticky header not visible (but accessible for screen readers) in case when menu is in homepage as a module
body:has(.m-ai-search) .js-sticky-header-element.is-affixed {
  @include visually-hidden();
}
